import React from 'react'
import Layout from '../components/layout'
import UiDesigner from '../components/categories/ui-designer'
import Seo from '../components/seo'

const UiDesignerPage = ({ location }) => {
  return (
    <Layout location={location}>
      <UiDesigner />
    </Layout>
  )
}

export const Head = ({ location }) => {
  const title = 'Top UI Designer Freelancers | Codestaff'
  const description =
    'Hire the best UI Designer freelancers at Codestaff. Get the top 1% of UI Designer professionals to join your team.'

  return <Seo type="page" title={title} description={description} />
}

export default UiDesignerPage
